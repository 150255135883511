import React, { useEffect } from 'react'
import {
  graphql,
  HeadProps,
  navigate,
  PageProps,
  StaticQueryDocument,
} from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { PrismicRichText } from '@prismicio/react'

import BertholdTitle from 'src/components/atoms/bertholdTitle'
import { WithContext, Article } from 'schema-dts'
import DocumentHead from 'src/components/pageLayout/documentHead'

import {
  ArticleQuery,
  Maybe,
  PrismicArticle,
  PrismicCategory,
  PrismicSiteInformation,
  Site,
} from 'src/typings/generated/graphql'

// import { CircleFadeLoader } from 'react-loaders-kit'

import CustomLink from 'src/utils/customLink'
import CategoryPill, { IPillStyle } from 'src/components/atoms/categoryPill'
import RecentArticles from 'src/components/organisms/engagement/recentArticles'

import WhiteWave from 'src/images/svgs/WhiteWave.svg'
import Eyebrow from 'src/images/svgs/Eyebrow.svg'
import TwitterIcon from 'src/images/svgs/icons/Xicon.svg'
import FacebookIcon from 'src/images/svgs/icons/FacebookIcon.svg'
import LinkedInIcon from 'src/images/svgs/icons/LinkedInIcon.svg'

import { articleBannerImageUrl } from 'src/utils/articlesHelper'

import * as Styles from './{prismicArticle.url}.module.scss'

const ArticleTemplate = ({ data }: PageProps<ArticleQuery>) => {
  if (!data) return null

  const article = data.prismicArticle as PrismicArticle
  const category = article.data.category?.document as Maybe<PrismicCategory>

  const shareUrl = `https://raisingcanes.com${article.url}`
  const redirectUrl = article.data.url?.url

  useEffect(() => {
    if (redirectUrl && typeof window !== 'undefined') {
      navigate(redirectUrl)
    }
  }, [redirectUrl])

  const publishedDate = () => {
    const dateStr = article.data.post_date ?? article.first_publication_date
    const date = new Date(dateStr)

    return date
      .toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      })
      .replaceAll('/', '.')
  }

  const hero = () => {
    const categoryPill = category ? (
      <div className={Styles.categoryPillEyebrow}>
        <CategoryPill
          category={category.data.name ?? ''}
          style={'grey-gold' as IPillStyle}
        />
      </div>
    ) : (
      <div className={Styles.eyebrow}>
        <Eyebrow />
      </div>
    )

    if (article.data.banner_image?.gatsbyImageData) {
      return (
        <div className={`${Styles.hero} ${Styles.banner}`}>
          <div className={Styles.imageWrapper}>
            {!!article.data.banner_image?.gatsbyImageData && (
              <GatsbyImage
                className={Styles.image}
                image={article.data.banner_image?.gatsbyImageData}
                alt={article.data.banner_image?.alt ?? ''}
              />
            )}
            <div className={Styles.imageOverlay} />
          </div>
          <div className={Styles.headline}>
            {categoryPill}
            <h1>
              {article.data.title?.text &&
                BertholdTitle({ text: article.data.title?.text })}
            </h1>
            <p>{publishedDate()}</p>
          </div>
          <div className={Styles.wave}>
            <WhiteWave />
          </div>
        </div>
      )
    }

    return (
      <div className={Styles.hero}>
        <div className={Styles.headline}>
          {categoryPill}
          <h1>{article.data.title?.text ?? ''}</h1>
          <p>{publishedDate()}</p>
        </div>
      </div>
    )
  }

  return (
    <div data-testid="article-template">
      {redirectUrl && (
        <div className={Styles.loader}>
          {/* TODO: do we replace loader? */}
          {/* <CircleFadeLoader loading color="#d71c2e" /> */}
          <div />
        </div>
      )}
      {!redirectUrl && (
        <div className={Styles.article}>
          {hero()}
          {article.data.url?.url && <p>{article.data.url?.url}</p>}
          <div className={Styles.bodyContainer}>
            <div className={Styles.body}>
              <PrismicRichText
                field={article.data.body?.richText}
                components={{
                  hyperlink: ({ node, children }: any) =>
                    CustomLink({ link: node.data, children }),
                }}
              />
              <div className={Styles.social}>
                <p>Share:</p>
                <a
                  href={`http://twitter.com/intent/tweet/?url=${shareUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="share on twitter"
                >
                  <TwitterIcon />
                </a>
                <a
                  href={`http://www.facebook.com/share.php?u=${shareUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="share on facebook"
                >
                  <FacebookIcon />
                </a>
                <a
                  href={`https://www.linkedin.com/sharing/share-offsite/?url=${shareUrl}`}
                  target="_blank"
                  rel="noreferrer"
                  aria-label="share on linkedin"
                >
                  <LinkedInIcon />
                </a>
              </div>
            </div>
          </div>
          <RecentArticles
            content={{
              articleCategoryId:
                data.prismicArticle?.data.category?.id ?? undefined,
              headline: 'Recent Posts',
              hiddenArticleId: data.prismicArticle?.uid ?? undefined,
            }}
          />
        </div>
      )}
    </div>
  )
}

export default withPrismicPreview(ArticleTemplate)

export const Head = ({ data }: HeadProps<ArticleQuery>) => {
  const { prismicArticle, prismicSiteInformation, site } = data

  const {
    data: pageData,
    url,
    first_publication_date: firstPublicationDate,
    last_publication_date: lastPublicationDate,
    tags,
  } = prismicArticle as PrismicArticle

  const { data: siteInformationData } =
    prismicSiteInformation as PrismicSiteInformation

  const { siteMetadata } = site as Site

  const siteTitle =
    siteInformationData.site_name ?? siteMetadata?.title ?? 'Article'
  const title = pageData.meta_title ?? pageData.title?.text ?? siteTitle

  const description = pageData.meta_description ?? ''

  const siteImageUrl = siteInformationData.site_icon?.url ?? ''
  const imageUrl =
    articleBannerImageUrl(prismicArticle as PrismicArticle) ?? siteImageUrl
  const noIndex = pageData.meta_no_index ?? undefined

  const schemaData = {
    author: pageData.author ?? '',
    datePublished: pageData.post_date ?? firstPublicationDate ?? '',
    dateModified: lastPublicationDate ?? '',
  }

  const schema: WithContext<Article> = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    author: {
      '@type': 'Person',
      name: schemaData?.author ?? '',
    },
    copyrightHolder: {
      '@type': 'Organization',
      name: 'Raising Restaurants, LLC',
    },
    copyrightYear: schemaData?.datePublished.substring(0, 4),
    creator: {
      '@type': 'Person',
      name: schemaData?.author ?? '',
    },
    publisher: {
      '@type': 'Organization',
      name: siteTitle,
      logo: {
        '@type': 'ImageObject',
        url: siteImageUrl,
      },
    },
    datePublished: schemaData?.datePublished,
    dateModified: schemaData?.dateModified,
    description,
    headline: title,
    inLanguage: 'en',
    name: title,
    image: {
      '@type': 'ImageObject',
      url: imageUrl,
    },
    keywords: tags,
  }

  return DocumentHead({
    title,
    url: url ?? '',
    description: '',
    imageUrl: '',
    pageSchema: schema,
    noIndex,
  })
}

export const query: StaticQueryDocument = graphql`
  query Article($id: String) {
    prismicArticle(id: { eq: $id }) {
      _previewable
      uid
      data {
        title {
          text
        }
        body {
          richText
        }
        category {
          id
          document {
            ...CategoryFragment
          }
        }
        banner_image {
          gatsbyImageData
          alt
        }
        url {
          url
        }
        author
        post_date
        meta_title
        meta_description
        meta_image {
          url
        }
        meta_no_index
      }
      url
      first_publication_date
      tags
    }
    site {
      siteMetadata {
        title
        description
        url
        author
      }
    }
    prismicSiteInformation {
      data {
        site_name
        site_description
        site_icon {
          url
        }
      }
    }
  }
`
